import React, { useEffect, useState } from "react"
import Arrow from "../images/arrow_orange.svg"
import ArrowWhite from "../images/arrow_white.svg"
import {
  Container,
  ArrowContainer,
  InfoName,
  ArrowDown,
  AccordionInfo,
  InfoContainer,
  HeaderContainer,
} from "../styles/accordion.style"

export default function FilterAccordion({
  name,
  background,
  showDetailDefault = true,
  children,
  isTypeFilter = false
}) {
  const [showDetail, setShowDetail] = useState(showDetailDefault)

  useEffect(() => {
    setShowDetail(showDetailDefault)
  }, [])

  const toggleDetail = () => setShowDetail(showDetail => !showDetail)
  return (
    <Container isFilter={true}>
      <HeaderContainer
        color={background ? background : null}
        className="Row"
        isFilter={true}
        onClick={toggleDetail}
      >
        <InfoContainer>
          <InfoName>{name}</InfoName>
        </InfoContainer>

        <ArrowContainer>
          <ArrowDown src={background ? ArrowWhite : Arrow} open={showDetail} />
        </ArrowContainer>
      </HeaderContainer>
      <AccordionInfo open={showDetail} isFilter={true} isTypeFilter={isTypeFilter}>
        {children}
      </AccordionInfo>
    </Container>
  )
}
