import React from "react"
import styled from "styled-components"
import CheckedBox from "../images/checked_box_tick.svg"
import CheckBoxOutline from "../images/checkbox_outline.svg"

const Label = styled.span`
  font-weight: 500;
  font-size: 1em;
  line-height: 32px;
  color: #ffffff;
  margin-left: 13px;
  margin-top: 15px;
`

const CheckImage = styled.img`
  cursor: pointer;
  width: 21px;
  height: 21px;
  margin-top: 22px;
`
export default function CheckBox({ type, onChange, label }) {
  const toggleCheck = () => {
    if (onChange) onChange(label)
  }

  return (
    <div className="Row">
      {type === label ? (
        <CheckImage src={CheckedBox} alt="Checked box" onClick={toggleCheck} />
      ) : (
        <CheckImage
          src={CheckBoxOutline}
          alt="Check Box"
          onClick={toggleCheck}
        />
      )}
      <Label>{label}</Label>
    </div>
  )
}
