import React from "react"
import styled from "styled-components"
import Arrow from "../images/arrow_orange.svg"

const SelectWrapper = styled.div`
  position: relative;
  height: 56px;
  max-width: 366px;
`

const SelectArrow = styled.div`
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%) rotate(90deg);
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

const SelectElement = styled.select`
  height: 100%;
  width: 100%;
  padding: 16px;
  border: none;
  background-color: #fff;
  border-radius: 8px;
  appearance: none;
  cursor: pointer;
  max-width: 366px;
  position: relative;
  font-size: 1em;
  line-height: 16px;
  font-weight: 500;
  color: #888888;

  &:focus {
    outline: none;
    border: 2px solid #f60;
  }
`

const Select = ({ options, placeHolder, onChange }) => {
  // make select arrow clickable to show select options
  return (
    <SelectWrapper>
      <SelectElement onChange={onChange}>
        {options.map(option => (
          <option
            key={option.value}
            value={option.value}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </option>
        ))}
      </SelectElement>
      <SelectArrow>
        <img src={Arrow} alt="Arrow" />
      </SelectArrow>
    </SelectWrapper>
  )
}

export default Select
