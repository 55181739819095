import React from "react"
import moment from "moment"
import {
  DateContainerMobile,
  Date,
  MobileNameContainer,
  MobileMainContainer,
  DayMobile,
  EventMobileDetail,
  Ribbon,
} from "../../styles/eventList.style"
import Cancelled from "../../images/cancelled_ribbon.svg"
import Postponed from "../../images/postponed_ribbon.svg"

export default function MobileEventList({
  date,
  name,
  status,
  endDate,
  isMultiday,
  track,
  isEvent,
  event,
  openInfo,
}) {
  return (
    <MobileMainContainer className="Row" status={status}>
      <div
        className="Col"
        style={{ padding: 0 }}
        onClick={() => openInfo(event.id)}
      >
        {endDate ? (
          <DateContainerMobile>
            <DayMobile>
              <p style={{ marginTop: -2 }}>
                {isMultiday
                  ? moment(date, "MM-DD-YYYY")
                      .format("MMM")
                      .toUpperCase()
                  : moment(date, "MM-DD-YYYY")
                      .format("ddd")
                      .toUpperCase()}
              </p>
            </DayMobile>
            <Date>
              {isMultiday
                ? `${moment(date, "MM-DD-YYYY").format("D")} - ${moment(
                    endDate,
                    "MM-DD-YYYY"
                  ).format("D")}`
                : moment(date, "MM-DD-YYYY")
                    .format("MMM D")
                    .toUpperCase()}
            </Date>
          </DateContainerMobile>
        ) : (
          <DateContainerMobile>
            <DayMobile>
              <p style={{ marginTop: -2 }}>
                {moment(date, "MM-DD-YYYY")
                  .format("ddd")
                  .toUpperCase()}
              </p>
            </DayMobile>
            <Date>
              {moment(date, "MM-DD-YYYY")
                .format("MMM D")
                .toUpperCase()}
            </Date>
          </DateContainerMobile>
        )}
        <MobileNameContainer className="Col">
          <p style={{ fontWeight: 700 }}>{isEvent ? name : track}</p>{" "}
          <EventMobileDetail>{isEvent ? track : name}</EventMobileDetail>
        </MobileNameContainer>
      </div>
      {status !== "published" && (
        <Ribbon
          src={status === "cancelled" ? Cancelled : Postponed}
          alt="Ribbon"
          status={status}
          onClick={() =>
            openInfo(
              event.id,
              event.promoter_phone,
              event.promoter_email,
              event.status
            )
          }
        />
      )}
    </MobileMainContainer>
  )
}
