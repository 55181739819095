import { useQuery } from "@apollo/react-hooks"
import { gql } from "graphql-tag"

const GET_EVENTS = gql`
  query GetEventsV2($input: GetEventsInputV2!) {
    getEventsV2(input: $input) {
      id
      name
      start_date
      end_date
      isMultiDay
      status
      series {
        id
        name
      }
      track {
        id
        name
      }
      promoter_phone
      promoter_email
    }
  }
`

export function useGetEvents(options = {}) {
  return useQuery(GET_EVENTS, {
    variables: { input: options },
  })
}
