import React, { useCallback, useEffect, useRef, useState } from "react"
import EventList from "../../components/trackDetail/EventList"
import {
  EventDetailWrapper,
  DownloadText,
  EventFilterWrapper,
  EventBodyWrapper,
  EventBody,
  NoEventWrapper,
} from "../../styles/detail.style"
import { useGetEvents } from "../../gql/useGetEvents"
import SearchHeader from "../SearchHeader"
import FilterAccordion from "../FilterAccordion"
import CheckBox from "../CheckBox"
import { useMediaQuery } from "react-responsive"
import MobileEventList from "./MobileEventList"
import Loading from "../Loading"
import Select from "../Select"
import { GroupTitle } from "../../styles/trackAndSeries.style"
import moment from "moment"
import EventInfoModal from "../EventInfoModal"
import { graphql, navigate, useStaticQuery } from "gatsby"
import States from "../../utils/states"
import DownloadApp from "../DownloapAppModal"
import Button from "../Button"

export default function EventsPage() {
  let month = ""
  const [offset, setOffset] = useState(0)
  const [eventList, setEventList] = useState([])
  const [hasMore, setHasMore] = useState(false)

  const [queryString, setQueryString] = useState(null)
  const [search, setSearch] = useState(null)
  const [state, setState] = useState()
  const [type, setType] = useState()

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  const { loading, error, data } = useGetEvents({
    offset,
    ...(queryString ? { queryString } : null),
    ...(state ? { state } : null),
    ...(type ? { type } : null),
  })

  const observer = useRef()
  const lastTrackRef = useCallback(
    node => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(event => {
        if (event[0].isIntersecting && hasMore) {
          setOffset(offset => offset + 10)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  const filterValues = useStaticQuery(
    graphql`
      query GetFilterValues {
        tickethossGraphql {
          getTrackTypes {
            id
            key
            value
          }
        }
      }
    `
  )

  const trackTypes = filterValues.tickethossGraphql.getTrackTypes

  const events = !loading && data ? data.getEventsV2 : []

  useEffect(() => {
    if (offset === 0) setEventList(events)
    else setEventList(eventList => eventList.concat(events))
    setHasMore(events.length > 0)
  }, [data])

  const onSearch = () => {
    setQueryString(search)
    setEventList([])
    setOffset(0)
  }

  const handleKeyPress = e => {
    if (e.keyCode === 13) {
      setQueryString(search)
      setEventList([])
      setOffset(0)
    }
  }

  const handleStateChange = event => {
    setState(event.target.value)
    setEventList([])
  }

  const handleTypeChange = value => {
    console.log(value.target)
    setType(value.target.value)
    setEventList([])
  }

  const [openInfoModal, setOpenInfoModal] = useState(false)
  const [eventData, setEventData] = useState()
  const openInfo = (eventId, promoterPhone, promoterEmail, status) => {
    if (!promoterPhone) {
      navigate(`/event/${eventId}`)
    } else {
      setEventData({
        eventLink: `/event/${eventId}`,
        promoterPhone,
        promoterEmail,
        status,
      })
      if (isMobile) document.body.style.overflow = "hidden"
      setOpenInfoModal(`/event/${eventId}`)
    }
  }
  const onClose = () => {
    if (isMobile) document.body.style.overflow = "visible"
    setOpenInfoModal(false)
  }

  const [openDownloadApp, setOpenDownloadApp] = useState(false)
  const openDownloadModal = () => {
    document.body.style.overflow = "hidden"
    setOpenDownloadApp(true)
  }

  const onDownlodModalClose = () => {
    console.log("open the moooodalll")
    document.body.style.overflow = "visible"
    setOpenDownloadApp(false)
  }

  const reloadScreen = () => {
    window.location.reload()
  }

  return (
    <EventBody className="Row">
      <SearchHeader
        text="Events"
        setSearch={setSearch}
        handleKeyPress={handleKeyPress}
        search={search}
        onSearch={onSearch}
        searchText="Search Events"
      />
      {!error ? (
        <EventBodyWrapper className="Row">
          <EventFilterWrapper className="Col">
            <FilterAccordion name="STATE" isOpen={true}>
              <Select
                options={States.map(state => state)}
                placeholder="All States"
                onChange={handleStateChange}
              />
            </FilterAccordion>
            <FilterAccordion name="TYPE" isOpen={true}>
              <Select
                options={trackTypes.map(type => ({
                  value: type.value,
                  label: type.key,
                }))}
                placeholder="TYPE"
                onChange={handleTypeChange}
              />
            </FilterAccordion>
          </EventFilterWrapper>

          <EventDetailWrapper className="Col">
            {eventList.length > 0
              ? eventList.map((event, index) => {
                  const showTitle =
                    moment(event.start_date, "MM-DD-YYYY").format("MMMM") !==
                    month
                  if (showTitle)
                    month = moment(event.start_date, "MM-DD-YYYY").format(
                      "MMMM"
                    )

                  //Attach the ref to the last element
                  if (index + 1 === eventList.length) {
                    return (
                      <div ref={lastTrackRef} key={event.id}>
                        {!isMobile ? (
                          <>
                            {showTitle ? (
                              <DownloadText
                                style={{ marginTop: index !== 0 ? 56 : 0 }}
                              >
                                {month}
                              </DownloadText>
                            ) : null}
                            <EventList
                              id={event.id}
                              name={event.name}
                              date={event.start_date}
                              endDate={event.end_date}
                              isMultiday={event.isMultiDay}
                              status={event.status}
                              track={
                                event.track
                                  ? event.track.name
                                  : event.series
                                  ? event.series.name
                                  : null
                              }
                              marginTop={"45px"}
                              isEvent={true}
                              openDownloadModal={openDownloadModal}
                            />
                          </>
                        ) : (
                          <>
                            {showTitle ? (
                              <GroupTitle>{month}</GroupTitle>
                            ) : null}
                            <MobileEventList
                              name={event.name}
                              date={event.start_date}
                              endDate={event.end_date}
                              isMultiday={event.isMultiDay}
                              status={event.status}
                              track={
                                event.track
                                  ? event.track.name
                                  : event.series
                                  ? event.series.name
                                  : null
                              }
                              isEvent={true}
                              event={event}
                              openInfo={openInfo}
                            />
                          </>
                        )}
                      </div>
                    )
                  } else {
                    return !isMobile ? (
                      <div key={event.id}>
                        {showTitle ? (
                          <DownloadText
                            style={{ marginTop: index !== 0 ? 56 : 0 }}
                          >
                            {month}
                          </DownloadText>
                        ) : null}
                        <EventList
                          id={event.id}
                          name={event.name}
                          date={event.start_date}
                          endDate={event.end_date}
                          isMultiday={event.isMultiDay}
                          status={event.status}
                          track={
                            event.track
                              ? event.track.name
                              : event.series
                              ? event.series.name
                              : null
                          }
                          marginTop={"45px"}
                          isEvent={true}
                          openDownloadModal={openDownloadModal}
                        />
                      </div>
                    ) : (
                      <div key={event.id}>
                        {showTitle ? (
                          <DownloadText
                            key={event.id}
                            style={{ marginTop: index !== 0 ? 56 : 0 }}
                          >
                            {month}
                          </DownloadText>
                        ) : null}
                        <MobileEventList
                          key={event.id}
                          name={event.name}
                          date={event.start_date}
                          endDate={event.end_date}
                          isMultiday={event.isMultiDay}
                          status={event.status}
                          track={
                            event.track
                              ? event.track.name
                              : event.series
                              ? event.series.name
                              : null
                          }
                          isEvent={true}
                          event={event}
                          openInfo={openInfo}
                        />
                      </div>
                    )
                  }
                })
              : !loading && (
                  <NoEventWrapper>
                    <p style={{ maxWidth: "85%", marginInline: "auto" }}>
                      No Events match your current filters, please try adjusting
                      them.
                    </p>
                    <Button
                      onClick={reloadScreen}
                      text={"Reload Screen"}
                      style={{
                        minWidth: 145,
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                    />
                  </NoEventWrapper>
                )}
            {loading ? <Loading /> : null}
          </EventDetailWrapper>
        </EventBodyWrapper>
      ) : (
        <p>Sorry, couldn't fetch Event List at the moment.</p>
      )}

      {openInfoModal && (
        <EventInfoModal onClose={onClose} eventData={eventData} />
      )}

      {openDownloadApp && <DownloadApp onClose={onDownlodModalClose} />}
    </EventBody>
  )
}
